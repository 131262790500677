import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/auth/Login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/prijava',
        name: 'login',
        component: Login,
        meta: { permissions: [] }
    },
    {
        path: '/odjava',
        name: 'logout',
        meta: { permissions: [] },
        component: () => import('@/views/auth/Logout.vue')
    },
    {
        path: '/ponastavitev-gesla',
        name: 'passwordResetRequest',
        meta: { permissions: [] },
        component: () => import('@/views/auth/PasswordResetRequest.vue')
    },
    {
        //path: '/ponastavitev-gesla/:token',
        path: '/password-reset/:token',
        name: 'passwordReset',
        meta: { permissions: [] },
        component: () => import('@/views/auth/PasswordReset.vue')
    }, 
    /* ERRORS */
    {
        path: '*',
        name: '404',
        meta: { permissions: [] },
        component: () => import('@/views/errors/404.vue')
    },
    {
        path: '/403',
        name: '403',
        meta: { permissions: [] },
        component: () => import('@/views/errors/403.vue')
    },
    {
        path: '/500',
        name: '500',
        meta: { permissions: [] },
        component: () => import('@/views/errors/500.vue')
    },

    {
        path: '/',
        component: () => import('@/views/RootView.vue'),
        children: [
            {
                path: '/pregled',
                name: 'dashboard' ,
                component: () => import('@/views/Dashboard.vue'),
                alias: '',
                meta: { requiresAuth: true, permissions: ['zaton-view-events']}
            },
            
            {
                path: '/nastavitve',
                name: 'settings',
                component: () => import('@/views/Settings.vue'),
                meta: { requiresAuth: true, permissions: ['zaton-settings'] }
            },
            
            {
                path: '/uporabniki/seznam-uporabnikov',
                name: 'users_list',
                component: () => import('@/views/Users.vue'),
                meta: { requiresAuth: true, permissions: ['zaton-view-user']}
            },
            {
                path: '/uporabniki/dodaj-uporabnika',
                name: 'add_user',
                component: () => import('@/views/AddUser.vue'),
                meta: { requiresAuth: true, permissions: ['zaton-create-user']}
            },
            {
                path: '/uporabniki/seznam-uporabnikov/:id',
                name: 'edit_user',
                component: () => import('@/views/EditUser.vue'),
                meta: { requiresAuth: true, permissions: ['zaton-update-user']}
            },

            /*
            {
                path: 'enkratni-prehodi/registrske-stevilke',
                name: 'registration-numbers',
                component: () => import('@/views/RegistrationNumbers.vue'),
                //alias: '',
                meta: { requiresAuth: true, permissions: ['zaton-view-registration-number']}
            },
            */

            /*
            {
                path: 'zasedenost-parkirisca',
                name: 'parking_occupancy',
                component: () => import('@/views/ParkingOccupancy.vue'),
                //alias: '',
                meta: { requiresAuth: true, permissions: ['zaton-view-parking-occupancy']}
            },
            */

            {
                path:'/izvoz-podatkov',
                name: 'exports',
                component: () => import('@/views/Exports.vue'),
                meta: { requiresAuth: true,  permissions: ['zaton-data-export'] }
            },

            
            {
                path:'/zapornice/upravljanje',
                name: 'parking_barriers_management',
                component: () => import('@/views/ParkingBarriers.vue'),
                meta: { requiresAuth: true, permissions: ['zaton-parking-barriers'] }
            },

            {
                path:'/zapornice/arhiv',
                name: 'parking_barriers_archive',
                component: () => import('@/views/ParkingBarriersArchive.vue'),
                meta: { requiresAuth: true, permissions: ['zaton-parking-barriers'] }
            },

            /*{
                path:'zapornice/casovnica',
                name: 'parking_barriers_timeline',
                component: () => import('@/views/ParkingBarriersTimeline.vue'),
                meta: { requiresAuth: true, permissions: ['zaton-parking-barriers-timeline'] }
            },*/

            /*{
                path: 'zasedenost-parkirisca',
                name: 'parking_occupancy',
                component: () => import('@/views/ParkingOccupancy.vue'),
                //alias: '',
                meta: { requiresAuth: true}
            },

            
            {
                path:'validator',
                name: 'validator',
                component: () => import('@/views/Validator.vue'),
                meta: { requiresAuth: true }
            }*/
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: function(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 }
    },
    linkActiveClass: 'v-list__tile--active router-link-active active',
    routes
})

export default router