const Menu = [
    { header: 'Nadzorna Plošča' },
    {
        name: 'dashboard',
        title: 'Pregled',
        icon: 'mdi-view-dashboard',
        path: '/pregled',
        group: 'dashboard',
        permissions: ['zaton-view-events'],
        top_level: true
    },
    
    
    {
        name: 'settings',
        title: 'Nastavitve',
        icon: 'mdi-cogs',
        path: '/nastavitve',
        group: 'settings',
        permissions: ['zaton-settings'],
        top_level: true
    },
    
    
    /*
    {
        title: 'Uporabniki',
        icon: 'mdi-account-group',
        group:'uporabniki',
        permissions: ['zaton-view-user', 'zaton-create-user'],
        items: [
            {
                name: 'users_list',
                title: 'Seznam uporabnikov',
                icon: 'mdi-account-group',
                path: '/seznam-uporabnikov',
                group: 'users_list',
                permissions: ['zaton-view-user'],
                top_level: false
            },
            {
                name: 'add_user',
                title: 'Dodaj uporabnika',
                icon: 'mdi-account-plus',
                path: '/dodaj-uporabnika',
                group: 'add_user',
                permissions: ['zaton-view-user', 'zaton-create-user'],
                top_level: false
            },
        ]
    },
    */

    {
        name: 'users_list',
        title: 'Seznam uporabnikov',
        icon: 'mdi-account-group',
        path: '/seznam-uporabnikov',
        group: 'users_list',
        permissions: ['zaton-view-user'],
        top_level: true
    },
    {
        name: 'add_user',
        title: 'Dodaj uporabnika',
        icon: 'mdi-account-plus',
        path: '/dodaj-uporabnika',
        group: 'add_user',
        permissions: ['zaton-view-user', 'zaton-create-user'],
        top_level: true
    },

    /*{
        name: 'parking_occupancy',
        title: 'Zasedenost parkirišča',
        icon: 'mdi-car-3-plus',
        path: '/zasedenost-parkirisca',
        group: 'parking_occupancy',
    },*/

    /*
    {
        name: 'registration-numbers',
        title: 'Enkratni prehodi',
        icon: 'mdi-car-side',
        path: 'enkratni-prehodi/registrske-stevilke',
        group: 'vehicles',
        permissions: ['zaton-view-registration-number'],
        top_level: true
    },
    */

    /*
    {
        name: 'parking_occupancy',
        title: 'Zasedenost parkirišča',
        icon: 'mdi-car-3-plus',
        path: 'zasedenost-parkirisca',
        group: 'parking_occupancy',
        permissions: ['zaton-view-parking-occupancy'],
        top_level: true
    },
    */
    
    
    {
        title: 'Zapornice',
        icon: 'mdi-remote',
        group: 'zapornice', //group naziv mora biti enak kot  začetni path v router ... primer group: uporabniki, path: uporabniki/seznam ---> group: uporabniki == path: uporabniki/...
        permissions: ['zaton-parking-barriers'],
        items: [
            {
                name: 'parking_barriers_management',
                title: 'Upravljanje zapornic',
                icon: 'mdi-android-messages',
                path: '/zapornice/upravljanje',
                permissions: ['zaton-parking-barriers'],
            },

            /*{
                name: 'parking_barriers_timeline',
                title: 'Časovnica',
                icon: 'mdi-android-messages',
                path: '/zapornice/casovnica',
                permissions: ['zaton-parking-barriers-timeline'],
            },*/
            {
                name: 'parking_barriers_archive',
                title: 'Zgodovina',
                icon: 'mdi-android-messages',
                path: '/zapornice/arhiv',
                permissions: ['zaton-parking-barriers'],
            }
        ]
    },
    

    /*{
        name: 'validator',
        title: 'Validator',
        icon: 'mdi-barcode-scan',
        path: '/validacije',
        group: 'validator',
    }*/

    {
        name: 'exports',
        title: 'Izvoz podatkov',
        icon: 'mdi-application-export',
        path: 'izvoz-podatkov',
        group: 'exports',
        permissions: ['zaton-data-export'],
        top_level: true
    },
    
]

export { Menu }